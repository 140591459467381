//vip等级列表
export const vipLevelCols = [
  {
    prop: 'level',
    label: '等级',
    minWidth: '200',
    sortable: true
  },
  {
    prop: 'levelName',
    label: '等级名称',
    minWidth: '200'
  },
  {
    prop: 'levelNumber',
    label: '等级人数',
    minWidth: '200',
    slotName: 'levelNumberSlot'
  },
  {
    prop: 'priceDiscount',
    label: '商品价格折扣',
    minWidth: '200',
    slotName: 'goodsRateSlot'
  },
  {
    prop: 'state',
    label: '状态',
    minWidth: '200',
    slotName: 'stateSlot'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: '200',
    slotName: 'createTimeSlot'
  }
]

//分销商列表
export const cols = [
  {
    prop: 'accountName',
    label: '分销商',
    minWidth: '280'
  }
]
